import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Col, Row } from "react-grid-system";
import { withTranslation } from "react-i18next";

import styles from "./ContactsLeftMenu.module.scss";

import httpClient from "../../lib/HttpClient";

import security from "../../services/Security";

import accountSetting from "../../services/AccountSetting";

class ContactsLeftMenuBuild extends React.Component {
  constructor() {
    super();

    const currentUserRole = security.getUserRole();
    const currentUserId = security.getUserId();

    httpClient.get(`/users/${currentUserId}.json`).then((response) => {
      this.setState({ currentTeamName: response.data.current_team.name });
    });

    this.state = {
      currentUser: {
        id: currentUserId,
        role: currentUserRole,
      },
      currentTeamName: null,
    };
  }

  render() {
    const { t } = this.props;

    const segments = window.location.pathname.match(/^\/contacts\/segments/)
      ? true
      : false;
    const verified_emails = window.location.pathname.match(
      /^\/contacts\/mailer/
    )
      ? true
      : false;
    const analytics = window.location.pathname.match(/^\/contacts\/analytics/)
      ? true
      : false;
    const contacts =
      window.location.pathname.match(/^\/contacts/) &&
      !segments &&
      !verified_emails &&
      !analytics
        ? true
        : false;

    return (
      <Col md={2} className={styles.leftSideMenuContainer}>
        {this.state.currentUser.role !== "viewer" && (
          <div className={styles.leftSideMenu}>
            <div className={[styles.leftSideMenuItem, styles.header].join(" ")}>
              {t("crm.crm")}
            </div>
            <div
              className={`${contacts ? styles.selected : ""} ${
                styles.leftSideMenuItem
              }`}
            >
              <a href="/contacts">{t("crm.contacts")}</a>
            </div>
            <div
              className={`${segments ? styles.selected : ""} ${
                styles.leftSideMenuItem
              }`}
            >
              <a href="/contacts/segments">{t("crm.segments")}</a>
            </div>
          </div>
        )}

        <div>
          <div
            className={`${verified_emails ? styles.selected : ""} ${
              styles.leftSideMenuItem
            }`}
          >
            <a href="/contacts/mailer">{t("crm.verified_emails")}</a>
          </div>
          {this.state.currentTeamName && (
            <div
              className={`${styles.team} ${analytics ? styles.selected : ""} ${
                styles.leftSideMenuItem
              }`}
            >
              <a href="/teams">
                Team:{" "}
                <span className={styles.currentTeam}>
                  {this.state.currentTeamName}
                </span>
              </a>
            </div>
          )}
        </div>
      </Col>
    );
  }
}

const ContactsLeftMenu = withTranslation()(ContactsLeftMenuBuild);

export default ContactsLeftMenu;
