import React, { useState, useEffect } from "react";
import chroma from "chroma-js";
import DatePicker from "react-datepicker";
import Select, { StylesConfig } from "react-select";
import makeAnimated from "react-select/animated";

import history from "../../../../history";
import httpClient from "../../../../lib/HttpClient";
import classNames from "classnames";
import security from "../../../../services/Security";
import notificationService from "../../../../services/Notifications";

import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikHeadline,
  UikDivider,
  UikDropdown,
  UikDropdownItem,
  Uikon,
  UikToggle,
} from "@uik";

import NewModal from "../../../Common/Modal";
import CloseIcon from "../../../../images/icons/svg/close-grey.svg";
import Button from "../../../Common/Buttons/ButtonPrimary";
import styles from "./EditItemModal.module.scss";

const animatedComponents = makeAnimated();

export default (props) => {
  const [emailCampaign, setEmailCampaign] = useState(props.selectedItem);
  const [contacts, setContacts] = useState(null);
  const [contactSegments, setContactSegments] = useState(null);
  const [contactsSelectList, setContactsSelectList] = useState(null);
  const [contactSegmentsSelectList, setContactSegmentsSelectList] =
    useState(null);
  const [newSendDate, setNewSendDate] = useState(
    new Date(props.selectedItem.send_date)
  );
  const [updatedEmailCampaignProperties, setUpdatedEmailCampaignProperties] =
    useState({});
  const [selectedContactsList, setSelectedContactsList] = useState(null);
  const [selectedContactSegmentsList, setSelectedContactSegmentsList] =
    useState(null);

  const [emailCampaignProps, setEmailCampaignProps] = useState({});

  useEffect(() => {
    httpClient.get(`/contacts.json`).then((res) => {
      setContacts(res.data);

      let list = [];

      res.data.map((contact, idx) => {
        let contactNameplate = `${contact.first_name} ${contact.last_name} <${contact.email}>`;
        list.push({
          value: contact.id,
          label: contactNameplate,
        });
      });

      setContactsSelectList(list);

      // Now set the selected email campaign's contact selection
      let selectedEmailCampaignContactList = [];

      emailCampaign.contacts.map((contact, idx) => {
        let contactNameplate = `${contact.first_name} ${contact.last_name} <${contact.email}>`;

        selectedEmailCampaignContactList.push({
          value: contact.id,
          label: contactNameplate,
        });
      });

      setSelectedContactsList(selectedEmailCampaignContactList);

      // set the EmailCampaignProps in with contacts and contact_segments in the appropriate format to facillitate editing
      let emailCampaignsCopy = { ...emailCampaign };

      let contactSegmentIds = emailCampaignsCopy.contact_segments.map((cs) => {
        return cs.id;
      });

      let contactIds = emailCampaignsCopy.contacts.map((c) => {
        return c.id;
      });

      emailCampaignsCopy["contacts"] = contactIds;

      emailCampaignsCopy["contact_segments"] = contactSegmentIds;

      setEmailCampaignProps(emailCampaignsCopy);
    });

    httpClient.get(`/contact_segments.json`).then((res) => {
      setContactSegments(res.data);

      let list = [];

      res.data.map((contactSegment, idx) => {
        let contactSegmentNameplate = `Segment: ${contactSegment.name}`;

        list.push({
          value: contactSegment.id,
          label: contactSegmentNameplate,
        });
      });

      setContactSegmentsSelectList(list);

      // Now set the selected email campaign's contact segment selection
      let selectedEmailCampaignContactSegmentsList = [];

      emailCampaign.contact_segments.map((contactSegment, idx) => {
        let contactSegmentNameplate = `Segment: ${contactSegment.name}`;

        selectedEmailCampaignContactSegmentsList.push({
          value: contactSegment.id,
          label: contactSegmentNameplate,
        });
      });

      setSelectedContactSegmentsList(selectedEmailCampaignContactSegmentsList);
    });
  }, []);

  const renderForm = () => {
    return (
      <UikFormInputGroup className={styles.formContainer}>
        {[
          "Name",
          "Contact Segments",
          "Contacts",
          "From",
          "Subject",
          "Body",
          "Send Date",
        ].map((propertyLabel) => {
          return renderPropertyInput(propertyLabel);
        })}
      </UikFormInputGroup>
    );
  };

  const handleClose = () => {
    props.reloadEmailCampaigns();
    props.setShowEditEmailCampaignModal(false);
  };

  const handlePropertyValueChange = (e, propertyId) => {
    let ecProps = { ...emailCampaignProps };

    ecProps[propertyId] = e.target.value;

    setEmailCampaignProps(ecProps);
  };

  const handleSendDateChange = (date, propertyId) => {
    let ecProps = { ...emailCampaignProps };

    let selectedSendDate = date ? date.toISOString() : null;

    ecProps[propertyId] = selectedSendDate;

    setEmailCampaignProps(ecProps);
    setNewSendDate(date);
  };

  const handleContactsSelection = (newValue: any, actionMeta: any) => {
    const tagsCheck = {};
    let tagsList = [];

    if (newValue) {
      let ecProps = { ...emailCampaignProps };

      newValue.forEach((tag) => {
        console.log("tag", tag);
        if (!tagsCheck[tag["value"]]) {
          // Add it to the tag list
          tagsList.push(tag.value);
          tagsCheck[tag["value"]] = true;
        }
      });

      ecProps["contacts"] = tagsList;

      setEmailCampaignProps(ecProps);
      setSelectedContactsList(newValue);
    }
    if (actionMeta.action === "remove-value") {
      let ecProps = { ...emailCampaignProps };
      let contactsList = ecProps["contacts"];

      let newValues = selectedContactsList.filter(
        (contact) => contact.value !== actionMeta.removedValue.value
      );

      ecProps["contacts"] = contactsList.filter(
        (contact) => contact !== actionMeta.removedValue.value
      );

      setEmailCampaignProps(ecProps);
      setSelectedContactsList(newValues);
    }
  };

  const handleContactSegmentsSelection = (newValue: any, actionMeta: any) => {
    if (newValue) {
      const segmentsCheck = {};

      let segmentsList = [];
      let ecProps = { ...emailCampaignProps };

      newValue.forEach((segment) => {
        if (!segmentsCheck[segment["value"]]) {
          // Add it to the segment list
          segmentsList.push(segment.value);
          segmentsCheck[segment["value"]] = true;
        }
      });

      ecProps["contact_segments"] = segmentsList;

      setEmailCampaignProps(ecProps);
      setSelectedContactSegmentsList(newValue);
    }

    if (actionMeta.action === "remove-value") {
      let ecProps = { ...emailCampaignProps };
      let segmentsList = ecProps["contact_segments"];

      let newValue = selectedContactSegmentsList.filter(
        (seg) => seg.value !== actionMeta.removedValue.value
      );

      ecProps["contact_segments"] = segmentsList.filter(
        (seg) => seg !== actionMeta.removedValue.value
      );

      setEmailCampaignProps(ecProps);
      setSelectedContactSegmentsList(newValue);
    }
  };

  const renderPropertyInput = (propertyLabel) => {
    let propertyId = propertyLabel.toLowerCase().replace(/ /, "_");

    if (propertyLabel === "Body") {
      return (
        <div className={styles.customInputContainer}>
          <div>
            <div className={styles.label}>
              <span className="uik-content-title__wrapper">Body</span>
            </div>
            <textarea
              disabled={!emailCampaign.editable}
              className={`uik-input__input `}
              style={{ width: "100%" }}
              rows="5"
              label="Body"
              placeholder="Enter message, utilize variables {{first_name}} {{last_name}}, generate unique share links with {{share|123|click here to see document}}"
              onChange={(e) => {
                handlePropertyValueChange(e, propertyId);
              }}
              value={emailCampaignProps.body}
            ></textarea>
          </div>
        </div>
      );
    } else if (propertyId === "send_date") {
      return (
        <div>
          <div className={styles.header}>
            <span
              className={`uik-content-title__wrapper`}
              style={{ display: "block" }}
            >
              Send Date
            </span>
          </div>
          {!emailCampaign.editable ? (
            <div className={styles.datePickerContainer}>
              <DatePicker
                className={`uik-input__input`}
                disabled
                dateFormat="MMMM d, yyyy h:mm aa"
                showTimeSelect
                selected={new Date(emailCampaign.send_date)}
                onChange={(e) => {
                  handleSendDateChange(e, propertyId);
                }}
              />
            </div>
          ) : (
            <div className={styles.datePickerContainer}>
              <DatePicker
                className={`uik-input__input`}
                isClearable
                dateFormat="MMMM d, yyyy h:mm aa"
                showTimeSelect
                selected={newSendDate}
                onChange={(e) => {
                  handleSendDateChange(e, propertyId);
                }}
              />
            </div>
          )}
        </div>
      );
    } else if (propertyId === "contacts") {
      const customCreatableSelectStyles = {
        control: (base) => ({
          ...base,
          maxHeight: 100,
          overflow: "auto",
          autoComplete: "off",
          role: "presentation",
        }),
      };

      return (
        <div>
          <div className={styles.header}>
            <span
              className={`uik-content-title__wrapper`}
              style={{ display: "block" }}
            >
              Contacts
            </span>
          </div>
          <Select
            isDisabled={!emailCampaign.editable}
            isMulti
            isClearable={false}
            components={animatedComponents}
            styles={customCreatableSelectStyles}
            options={contactsSelectList}
            onChange={handleContactsSelection}
            value={selectedContactsList || []}
          />
        </div>
      );
    } else if (propertyId === "contact_segments") {
      const customCreatableSelectStyles = {
        control: (base) => ({
          ...base,
          maxHeight: 100,
          overflow: "auto",
          autoComplete: "off",
          role: "presentation",
        }),
      };

      return (
        <div>
          <div className={styles.header}>
            <span
              className={`uik-content-title__wrapper`}
              style={{ display: "block" }}
            >
              Segments
            </span>
          </div>
          <Select
            isDisabled={!emailCampaign.editable}
            isMulti
            isClearable={false}
            components={animatedComponents}
            styles={customCreatableSelectStyles}
            options={contactSegmentsSelectList}
            onChange={handleContactSegmentsSelection}
            value={selectedContactSegmentsList || []}
          />
        </div>
      );
    } else {
      return (
        <div>
          <UikInput
            label={`${propertyLabel}`}
            placeholder={`${propertyLabel}`}
            disabled={!emailCampaign.editable}
            onChange={(e) => {
              handlePropertyValueChange(e, propertyId);
            }}
            value={emailCampaignProps[propertyId]}
          />
        </div>
      );
    }
  };

  const handleSave = () => {
    httpClient
      .put(`/email_campaigns/${emailCampaign.id}.json`, {
        email_campaign: emailCampaignProps,
      })
      .then((response) => {
        console.log("response", response);
        handleClose();
      });
  };

  const handleStop = () => {
    let updatedEmailCampaign = { ...emailCampaign };

    updatedEmailCampaign["status"] = "cancelled";

    httpClient
      .put(`/email_campaigns/${emailCampaign.id}.json`, {
        email_campaign: updatedEmailCampaign,
      })
      .then((response) => {
        console.log("response", response);
        handleClose();
      });
  };

  return (
    <NewModal visible={true} type="regular">
      <div className="heading">Edit Verified Email</div>
      <UikDivider />
      <div className="body">
        <div>{renderForm()}</div>
      </div>
      <div className="clear" />
      <div className="dividerContainer">
        <UikDivider />
      </div>
      <div className="buttons">
        <div className={styles.buttonContainer}>
          <div className={styles.leftContainer}>
            {emailCampaign.stoppable && (
              <UikButton onClick={(e) => handleStop()} error>
                Stop
              </UikButton>
            )}
          </div>
          <div className={styles.rightContainer}>
            {emailCampaign.editable && (
              <UikButton onClick={(e) => handleSave(e)} primary>
                Save
              </UikButton>
            )}
            <UikButton onClick={() => handleClose()}>Cancel</UikButton>
          </div>
        </div>
      </div>
    </NewModal>
  );
};
