/* External libraries */
import React from "react";
import CacheBuster from "react-cache-buster";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import { version } from "../package.json";

/* Assets */
import "./App.css";

/* Components */
import Login from "./components/Login/Login";
import Users from "./components/Users/Users";
import Libraries from "./components/Libraries/Libraries";
import References from "./components/Documents/References";
import Documents from "./components/Documents/Documents";
import DocumentLibraries from "./components/Documents/DocumentLibraries";
import ReferenceLibraries from "./components/Documents/ReferenceLibraries";
import DocumentViewer from "./components/Documents/DocumentViewer";
import ReferenceViewer from "./components/Documents/ReferenceViewer";
import Approvers from "./components/Documents/Approvers";
import ApprovalsExport from "./components/Documents/ApprovalsExport";
import DraftUpload from "./components/Drafts/Upload/DraftUpload";
import DraftUpdate from "./components/Drafts/Upload/DraftUpdate";
import DraftVideoUpdate from "./components/Drafts/Upload/DraftVideoUpdate";
import Drafts from "./components/Drafts/Drafts";
import Editor from "./components/Editor/Editor";
import PasswordCreate from "./components/Password/PasswordCreate";
import PasswordForgot from "./components/Password/PasswordForgot";
import PasswordLinkExpired from "./components/Password/PasswordLinkExpired";
import PasswordSetup from "./components/Password/PasswordSetup";
import Tags from "./components/Tags/Tags";
import NewAccount from "./components/2FA/NewAccount";
import ReferencesLibrary from "./components/ReferencesLibrary";
import SetupSecurity from "./components/SetupSecurity";
import PersonalInfo from "./components/PersonalInfo";
import SetupNotifications from "./components/SetupNotifications";
import Language from "./components/Language";
import AuditView from "./components/AuditView";
import ShareLinkAnalytics from "./components/Documents/ShareLinkAnalytics";
import StructuredContent from "./components/StructuredContent";
import Dashboard from "./components/Dashboard";
import Teams from "./components/Teams";

import history from "./history";

import Notification from "./components/Common/Notification";

import security from "./services/Security";
import accountSetting from "./services/AccountSetting";
import ActivityLog from "./components/ActivityLog";
import { ActivateAccount } from "./components/Login/ActivateAccount";
import CompareDocuments from "./components/Documents/CompareDocuments";
import ShareLinkViewer from "./components/ShareLinkViewer/ShareLinkViewer";
import GuestViewer from "./components/GuestViewer/GuestViewer";

import Contacts from "./components/Contacts/Contacts";
import ContactSegments from "./components/Contacts/Segments/Segments";
import EmailCampaigns from "./components/Contacts/EmailCampaigns/EmailCampaigns";
import Emails from "./components/Contacts/Emails/Emails";
import Unsubscribe from "./components/Unsubscribe/Unsubscribe";

/* Variables */

class App extends React.Component {
  constructor() {
    accountSetting.getSettings();

    super();
  }

  render() {
    if (security.checkAuth()) {
      const isProduction = process.env.NODE_ENV === "production";
      return (
        <CacheBuster
          currentVersion={version}
          isEnabled={isProduction} //If false, the library is disabled.
          isVerboseMode={false} //If true, the library writes verbose logs to console.
        >
          <div id="app_container">
            <Notification />
            <Router history={history}>
              <Switch>
                <Route exact path="/users" component={Users} />
                <Route exact path="/users/:id" component={Users} />
                <Route exact path="/libraries" component={Libraries} />
                <Route exact path="/libraries/:id" component={Libraries} />
                <Route exact path="/activitylog" component={ActivityLog} />
                <Route exact path="/personalinfo" component={PersonalInfo} />
                <Route exact path="/teams" component={Teams} />
                <Route exact path="/setupsecurity" component={SetupSecurity} />
                <Route
                  exact
                  path="/structured_content"
                  component={StructuredContent}
                />
                <Route
                  exact
                  path="/structured_content/categories/new"
                  component={StructuredContent}
                />
                <Route
                  exact
                  path="/structured_content/category/:id"
                  component={StructuredContent}
                />
                <Route
                  exact
                  path="/structured_content/category/:id/new_item"
                  component={StructuredContent}
                />
                <Route
                  exact
                  path="/structured_content/product_claims"
                  component={StructuredContent}
                />
                <Route
                  exact
                  path="/exportApprovals"
                  component={ApprovalsExport}
                />
                <Route
                  exact
                  path="/notifications"
                  component={SetupNotifications}
                />
                <Route
                  exact
                  path="/libraries/:id/documents"
                  component={Documents}
                />
                <Route
                  exact
                  path="/libraries/:id/references"
                  component={References}
                />
                <Route
                  exact
                  path="/documents/:id/update"
                  component={DraftUpdate}
                />

                <Route
                  exact
                  path="/documents/:id/approvers"
                  component={Approvers}
                />
                <Route exact path="/documents/:id" component={DocumentViewer} />
                <Route
                  exact
                  path="/document_libraries"
                  component={DocumentLibraries}
                />
                <Route
                  exact
                  path="/reference_libraries"
                  component={ReferenceLibraries}
                />
                <Route
                  exact
                  path="/references_library/:id"
                  component={ReferencesLibrary}
                />
                <Route
                  exact
                  path="/references/:id"
                  component={ReferenceViewer}
                />
                <Route
                  exact
                  path="/references/:id/:library"
                  component={ReferenceViewer}
                />
                <Route
                  exact
                  path="/references/:id/pid/:blockIdentifier"
                  component={ReferenceViewer}
                />
                <Route exact path="/tags" component={Tags} />
                <Route exact path="/tags/:id" component={Tags} />
                <Route
                  exact
                  path="/drafts/:id/approvers"
                  component={Approvers}
                />
                <Route
                  exact
                  path="/drafts/:id/update_video"
                  component={DraftVideoUpdate}
                />
                <Route
                  exact
                  path="/drafts/:id/update"
                  component={DraftUpdate}
                />
                <Route exact path="/drafts/upload" component={DraftUpload} />
                <Route exact path="/drafts/:id" component={DocumentViewer} />
                <Route exact path="/drafts" component={Drafts} />
                <Route exact path="/editor/:id" component={Editor} />
                <Route exact path="/audit" component={AuditView} />
                <Route
                  exact
                  path="/share_links"
                  component={ShareLinkAnalytics}
                />
                <Route exact path="/dashboard" component={Dashboard} />
                <Route
                  exact
                  path="/compare/:doc1_id/:doc2_id"
                  component={CompareDocuments}
                />
                <Route
                  exact
                  path="/share_links/view/:hash_url"
                  component={ShareLinkViewer}
                />
                {accountSetting.getSetting("multi_language") === "TRUE" && (
                  <Route exact path="/language" component={Language} />
                )}
                <Redirect
                  from="/:user_id/drafts/:document_id"
                  to={{
                    pathname: "/drafts/:document_id",
                    search: `${window.location.search}`,
                  }}
                />
                {accountSetting.getSetting("papercurve_crm") === "TRUE" && (
                  <Route exact path="/contacts" component={Contacts} />
                )}
                {accountSetting.getSetting("papercurve_crm") === "TRUE" && (
                  <Route
                    exact
                    path="/contacts/segments"
                    component={ContactSegments}
                  />
                )}
                {accountSetting.getSetting("papercurve_crm") === "TRUE" && (
                  <Route
                    exact
                    path="/contacts/segments/:id"
                    component={ContactSegments}
                  />
                )}
                {accountSetting.getSetting("papercurve_crm") === "TRUE" && (
                  <Route
                    exact
                    path="/contacts/mailer"
                    component={EmailCampaigns}
                  />
                )}
                {accountSetting.getSetting("papercurve_crm") === "TRUE" && (
                  <Route
                    exact
                    path="/contacts/mailer/:id"
                    component={EmailCampaigns}
                  />
                )}
                {accountSetting.getSetting("papercurve_crm") === "TRUE" && (
                  <Route
                    exact
                    path="/contacts/mailer/:id/emails"
                    component={Emails}
                  />
                )}
                {accountSetting.getSetting("papercurve_crm") === "TRUE" && (
                  <Route exact path="/contacts/:id" component={Contacts} />
                )}
                <Redirect to="/dashboard" />
              </Switch>
            </Router>
          </div>
        </CacheBuster>
      );
    } else {
      return (
        <div>
          <Notification />
          <Router history={history}>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/login" component={Login} />
              <Route
                exact
                path="/passwordlinkexpired"
                component={PasswordLinkExpired}
              />
              <Route
                exact
                path="/password/create/:token"
                component={PasswordCreate}
              />
              <Route
                exact
                path="/2fa/password/create/:token"
                component={NewAccount}
              />
              <Route
                exact
                path="/password/setup/:token/:title/:email/:documentId"
                component={PasswordSetup}
              />
              <Route exact path="/password/forgot" component={PasswordForgot} />
              <Route
                exact
                path="/password/forgot/:token"
                component={PasswordCreate}
              />
              <Route
                exact
                path="/:user_id/drafts/:document_id"
                component={ActivateAccount}
              />
              <Route
                exact
                path="/share_links/view/:hash_url"
                component={ShareLinkViewer}
              />
              <Route
                exact
                path="/guest/view/:hash_url"
                component={GuestViewer}
              />
              <Route
                exact
                path="/unsubscribe/:contact_email"
                component={Unsubscribe}
              />
              <Redirect to="/login" />
            </Switch>
          </Router>
        </div>
      );
    }
  }
}

export default App;
