import React, { useState, useEffect } from "react";
import chroma from "chroma-js";
import DatePicker from "react-datepicker";
import Select, { StylesConfig } from "react-select";
import makeAnimated from "react-select/animated";

import history from "../../../../history";
import httpClient from "../../../../lib/HttpClient";
import classNames from "classnames";
import security from "../../../../services/Security";
import notificationService from "../../../../services/Notifications";

import {
  UikFormInputGroup,
  UikInput,
  UikButton,
  UikHeadline,
  UikDivider,
  UikDropdown,
  UikDropdownItem,
  Uikon,
  UikToggle,
} from "@uik";

import NewModal from "../../../Common/Modal";
import CloseIcon from "../../../../images/icons/svg/close-grey.svg";
import Button from "../../../Common/Buttons/ButtonPrimary";
import styles from "./NewItemModal.module.scss";

const animatedComponents = makeAnimated();

export default (props) => {
  const [newStucturedContent, setNewStucturedContent] = useState("");
  const [emailCampaign, setEmailCampaign] = useState(null);
  const [editEmailCampaign, setEditEmailCampaign] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [contactSegments, setContactSegments] = useState(null);
  const [contactsSelectList, setContactsSelectList] = useState(null);
  const [contactSegmentsSelectList, setContactSegmentsSelectList] =
    useState(null);
  const [newSendDate, setNewSendDate] = useState(null);

  useEffect(() => {
    httpClient.get(`/contacts.json`).then((res) => {
      setContacts(res.data);

      let list = [];

      res.data.map((contact, idx) => {
        let contactNameplate = `${contact.first_name} ${contact.last_name} <${contact.email}>`;
        list.push({
          value: contact.id,
          label: contactNameplate,
        });
      });

      setContactsSelectList(list);
    });

    httpClient.get(`/contact_segments.json`).then((res) => {
      setContactSegments(res.data);

      let list = [];

      res.data.map((contactSegment, idx) => {
        let contactSegmentNameplate = `Segment: ${contactSegment.name}`;

        list.push({
          value: contactSegment.id,
          label: contactSegmentNameplate,
        });
      });

      setContactSegmentsSelectList(list);
    });
  }, []);

  const renderForm = () => {
    return (
      <UikFormInputGroup className={styles.formContainer}>
        {[
          "Name",
          "Contact Segments",
          "Contacts",
          "From",
          "Subject",
          "Body",
          "Send Date",
        ].map((propertyLabel) => {
          return renderPropertyInput(propertyLabel);
        })}
      </UikFormInputGroup>
    );
  };

  const handleClose = () => {
    props.setShowNewEmailCampaignModal(false);
    props.reloadEmailCampaigns();
  };

  const handlePropertyValueChange = (e, propertyId) => {
    let ecProps = { ...emailCampaign };

    ecProps[propertyId] = e.target.value;

    setEmailCampaign(ecProps);
  };

  const handleSendDateChange = (date, propertyId) => {
    let ecProps = { ...emailCampaign };

    let selectedSendDate = date ? date.toISOString() : null;

    ecProps[propertyId] = selectedSendDate;

    setEmailCampaign(ecProps);
    setNewSendDate(date);
  };

  const handleContactsSelection = (newValue: any, actionMeta: any) => {
    const tagsCheck = {};
    let tagsList = [];

    if (newValue) {
      let ecProps = { ...emailCampaign };

      newValue.forEach((tag) => {
        console.log("tag", tag);
        if (!tagsCheck[tag["value"]]) {
          // Add it to the tag list
          tagsList.push(tag.value);
          tagsCheck[tag["value"]] = true;
        }
      });

      ecProps["contacts"] = tagsList;

      setEmailCampaign(ecProps);
    }
  };

  const handleContactSegmentsSelection = (newValue: any, actionMeta: any) => {
    const tagsCheck = {};
    let tagsList = [];

    if (newValue) {
      let ecProps = { ...emailCampaign };

      newValue.forEach((tag) => {
        if (!tagsCheck[tag["value"]]) {
          // Add it to the tag list
          tagsList.push(tag.value);
          tagsCheck[tag["value"]] = true;
        }
      });

      ecProps["contact_segments"] = tagsList;

      setEmailCampaign(ecProps);
    }
  };

  const renderPropertyInput = (propertyLabel) => {
    let propertyId = propertyLabel.toLowerCase().replace(/ /, "_");

    if (propertyLabel === "Body") {
      return (
        <div className={styles.customInputContainer}>
          <div>
            <div className={styles.label}>
              <span className="uik-content-title__wrapper">Body</span>
            </div>
            <textarea
              className={`uik-input__input `}
              style={{ width: "100%" }}
              rows="5"
              label="Body"
              placeholder="Enter message, utilize variables {{first_name}} {{last_name}}, generate unique share links with {{share|123|click here to see document}}"
              onChange={(e) => {
                handlePropertyValueChange(e, propertyId);
              }}
            ></textarea>
          </div>
        </div>
      );
    } else if (propertyId === "send_date") {
      return (
        <div>
          <div className={styles.header}>
            <span
              className={`uik-content-title__wrapper`}
              style={{ display: "block" }}
            >
              Send Date
            </span>
          </div>
          <div className={styles.datePickerContainer}>
            <DatePicker
              className={`uik-input__input`}
              isClearable
              dateFormat="MMMM d, yyyy h:mm aa"
              showTimeSelect
              selected={newSendDate}
              onChange={(e) => {
                handleSendDateChange(e, propertyId);
              }}
            />
          </div>
        </div>
      );
    } else if (propertyId === "contacts") {
      const customCreatableSelectStyles = {
        control: (base) => ({
          ...base,
          maxHeight: 100,
          overflow: "auto",
          autoComplete: "off",
          role: "presentation",
        }),
      };

      return (
        <div>
          <div className={styles.header}>
            <span
              className={`uik-content-title__wrapper`}
              style={{ display: "block" }}
            >
              Contacts
            </span>
          </div>
          <Select
            isMulti
            isClearable={false}
            components={animatedComponents}
            styles={customCreatableSelectStyles}
            options={contactsSelectList}
            onChange={handleContactsSelection}
          />
        </div>
      );
    } else if (propertyId === "contact_segments") {
      const customCreatableSelectStyles = {
        control: (base) => ({
          ...base,
          maxHeight: 100,
          overflow: "auto",
          autoComplete: "off",
          role: "presentation",
        }),
      };

      return (
        <div>
          <div className={styles.header}>
            <span
              className={`uik-content-title__wrapper`}
              style={{ display: "block" }}
            >
              Segments
            </span>
          </div>
          <Select
            isMulti
            isClearable={false}
            components={animatedComponents}
            styles={customCreatableSelectStyles}
            options={contactSegmentsSelectList}
            onChange={handleContactSegmentsSelection}
          />
        </div>
      );
    } else {
      return (
        <div>
          <UikInput
            label={`${propertyLabel}`}
            placeholder={`${propertyLabel}`}
            disabled={false}
            onChange={(e) => {
              handlePropertyValueChange(e, propertyId);
            }}
          />
        </div>
      );
    }
  };

  const handleSave = () => {
    console.log(emailCampaign);
    httpClient
      .post("/email_campaigns.json", { email_campaign: emailCampaign })
      .then((response) => {
        console.log("response", response);
        handleClose();
      });
  };

  return (
    <NewModal visible={true} type="regular">
      <div className="heading">New Verified Email</div>
      <UikDivider />
      <div className="body">
        <div>{renderForm()}</div>
      </div>
      <div className="clear" />
      <div className="dividerContainer">
        <UikDivider />
      </div>
      <div className="buttons">
        <UikButton onClick={(e) => handleSave(e)} primary>
          Save
        </UikButton>
        <UikButton onClick={() => handleClose()}>Cancel</UikButton>
      </div>
    </NewModal>
  );
};
