/* External libraries */
import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import history from "../../../history";
import httpClient from "../../../lib/HttpClient";

import security from "../../../services/Security";
import notificationService from "../../../services/Notifications";
import TableView from "../../Common/TableView/TableView";

/* UI Kit */
import { UikInput, UikButton, Uikon } from "@uik";
import "@uik/styles.css";
import "../../../font.scss";

/* Papercurve Components */
import Header from "../../shared/Header/Header";
import ContactsLeftMenu from "../ContactsLeftMenu";
import NewItemModal from "./NewItemModal/NewItemModal";
import EditItemModal from "./EditItemModal/EditItemModal";

/* Assets */
import styles from "./EmailCampaigns.module.scss";

/* Variables */

class EmailCampaigns extends React.Component {
  constructor() {
    super();

    this.state = {
      emailCampaigns: [],
      filteredEmailCampaigns: [],
      selectedEmailCampaign: null,
      emailCampaignsSearchQuery: null,
      showNewEmailCampaignModal: false,
      showEditEmailCampaignModal: false,
      selectedEmailCampaignId: null,
    };
  }

  componentWillMount = () => {
    this.loadEmailCampaigns();
  };

  loadEmailCampaigns = () => {
    httpClient.get("/email_campaigns.json").then((response) => {
      this.setState({ emailCampaigns: response.data });
    });
  };

  componentWillReceiveProps = (nextProps) => {
    const selectedEmailCampaignId = nextProps.match.params.id;
    this.state.email_campaigns.map((email_campaign, index) => {
      if (email_campaign.id == selectedEmailCampaignId) {
        this.setState({ selectedEmailCampaign: email_campaign });
        this.setState({ selectedEmailCampaignId: email_campaign.id });
        this.setState({ showEditEmailCampaignModal: true });
      }
    });
  };

  handleNewVerifiedEmail = (e) => {
    this.setState({ showNewEmailCampaignModal: true });
  };

  setShowNewEmailCampaignModal = (visibility) => {
    let visible = visibility ? true : false;
    this.setState({ showNewEmailCampaignModal: visible });
  };

  handleEditVerifiedEmail = (e) => {
    this.setState({ showEditEmailCampaignModal: true });
  };

  setShowEditEmailCampaignModal = (visibility) => {
    let visible = visibility ? true : false;
    this.setState({ showEditEmailCampaignModal: visible });
  };

  goToEmailCampaign = (emailCampaignId) => {
    history.push(`/contacts/mailer/${emailCampaignId}/emails`);
  };

  editItem = (item) => {
    this.setState({ selectedEmailCampaign: item });
    this.setState({ showEditEmailCampaignModal: true });
  };

  renderStatus = (currentStatus) => {
    let currentStatusStyle;

    if (currentStatus === "created") {
      currentStatusStyle = styles.created;
    } else if (currentStatus === "generating") {
      currentStatusStyle = styles.generating;
    } else if (currentStatus === "pending") {
      currentStatusStyle = styles.pending;
    } else if (currentStatus === "processing") {
      currentStatusStyle = styles.processing;
    } else if (currentStatus === "completed") {
      currentStatusStyle = styles.completed;
    } else if (currentStatus === "cancelled") {
      currentStatusStyle = styles.cancelled;
    } else if (currentStatus === "edited") {
      currentStatusStyle = styles.edited;
    }

    return (
      <div className={styles.currentStatusContainer}>
        <div className={currentStatusStyle}>{currentStatus}</div>
      </div>
    );
  };

  render() {
    return (
      <Container fluid className={styles.emailCampaignsScreen}>
        <Header />
        {this.state.showNewEmailCampaignModal && (
          <NewItemModal
            setShowNewEmailCampaignModal={this.setShowNewEmailCampaignModal}
            reloadEmailCampaigns={this.loadEmailCampaigns}
          />
        )}
        {this.state.showEditEmailCampaignModal && (
          <EditItemModal
            reloadEmailCampaigns={this.loadEmailCampaigns}
            setShowEditEmailCampaignModal={this.setShowEditEmailCampaignModal}
            selectedItem={this.state.selectedEmailCampaign}
            emailCampaignId={this.state.selectedEmailCampaignId}
          />
        )}
        <Row>
          <ContactsLeftMenu />
          <Col>
            <div className={styles.emailCampaignsHeaderControls}>
              <div>&nbsp;</div>
              <div>
                <UikButton
                  onClick={() => {
                    this.handleNewVerifiedEmail();
                  }}
                  primary
                >
                  New
                </UikButton>
              </div>
            </div>
            <div className={styles.emailCampaignsTableContainer}>
              <TableView
                label="Verified Emails"
                items={this.state.emailCampaigns}
                itemFields={[
                  "status",
                  "name",
                  "send_date",
                  "number_of_recipients",
                  "clicks",
                  "downloads",
                  "actions",
                ]}
                itemClickFunction={this.editItem}
                largeColumns={["name"]}
                dateColumns={["send_date"]}
                secondaryClickFunction={this.goToEmailCampaign}
                statusColumns={["status"]}
                verifiedEmailActionColumn={"actions"}
                customStatusStyler={this.renderStatus}
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default EmailCampaigns;
