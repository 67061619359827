/* External libraries */
import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import history from "../../../history";
import httpClient from "../../../lib/HttpClient";

import security from "../../../services/Security";
import notificationService from "../../../services/Notifications";
import TableView from "../../Common/TableView/TableView";

/* UI Kit */
import { UikInput, UikButton, Uikon } from "@uik";
import "@uik/styles.css";
import "../../../font.scss";

/* Papercurve Components */
import Header from "../../shared/Header/Header";
import ContactsLeftMenu from "../ContactsLeftMenu";

/* Assets */
import styles from "./Emails.module.scss";

/* Variables */

class Emails extends React.Component {
  constructor() {
    super();

    this.state = {
      emails: [],
      filteredEmails: [],
      selectedEmail: null,
      emailsSearchQuery: null,
      showNewEmailModal: false,
      emailCampaign: { name: "Emails" },
    };
  }

  componentWillMount = () => {
    this.loadEmailCampaign();
    this.loadEmails();
  };

  loadEmailCampaign = () => {
    const selectedEmailCampaignId = this.props.match.params.id;

    httpClient
      .get(`/email_campaigns/${selectedEmailCampaignId}.json`)
      .then((response) => {
        this.setState({ emailCampaign: response.data });
      });
  };

  loadEmails = () => {
    const selectedEmailCampaignId = this.props.match.params.id;

    httpClient
      .get(`/email_campaigns/${selectedEmailCampaignId}/emails.json`)
      .then((response) => {
        let filteredEmails = [];

        this.setState({ emails: response.data });

        response.data.map((email_campaign, index) => {
          filteredEmails.push(email_campaign);
        });

        this.setState({ filteredEmails: filteredEmails });

        //response.data.map((email_campaign, index) => {
        //  if (email_campaign.id == selectedEmailCampaignId) {
        //    this.setState({ selectedEmail: email_campaign });
        //  }
        //});
      });
  };

  componentWillReceiveProps = (nextProps) => {
    const selectedEmailId = nextProps.match.params.id;
    this.state.email_campaigns.map((email_campaign, index) => {
      if (email_campaign.id == selectedEmailId) {
        this.setState({ selectedEmail: email_campaign });
      }
    });
  };

  renderEmailsList = () => {
    return (
      <div></div>
      //<div>
      //  {this.state.filteredEmails.map((email_campaign, index) => {
      //    const letter = email_campaign.name.charAt(0).toUpperCase();
      //    const headerExists = letterHeaders.includes(letter);

      //    if (!headerExists) {
      //      letterHeaders.push(letter);
      //    }

      //    return (
      //      <div key={email_campaign.id}>
      //        <EmailListItem email_campaign={email_campaign} selectedEmailId={selectedEmailId} />
      //      </div>
      //    );
      //  })}
      //</div>
    );
  };

  renderEmailEditForm = () => {
    return (
      <div></div>
      //<div>
      //  {this.state.selectedEmailId != "create" &&
      //    this.state.selectedEmail && (
      //      <EmailEditForm
      //        key={this.state.selectedEmail.id}
      //        reloadEmails={() => this.loadEmails()}
      //        onDelete={() => this.setState({ selectedEmail: null })}
      //        email_campaign={this.state.selectedEmail}
      //      />
      //    )}
      //</div>
    );
  };

  handleEmailCreate = (email_campaignFormData) => {};

  renderEmailCreateForm = () => {
    return (
      <div></div>
      //<div>
      //  <EmailCreateForm handleEmailCreate={this.handleEmailCreate} />
      //</div>
    );
  };

  handleEmailSearch = (e) => {};

  handleNewVerifiedEmail = (e) => {
    this.setState({ showNewEmailModal: true });
  };

  handleBackLink = () => {
    history.push("/contacts/mailer");
  };

  setShowNewEmailModal = (visibility) => {
    let visible = visibility ? true : false;
    this.setState({ showNewEmailModal: visible });
  };

  renderStatus = (currentStatus) => {
    let currentStatusStyle;

    if (currentStatus === "wait") {
      currentStatusStyle = styles.wait;
    } else if (currentStatus === "pending") {
      currentStatusStyle = styles.pending;
    } else if (currentStatus === "processing") {
      currentStatusStyle = styles.processing;
    } else if (currentStatus === "delivered") {
      currentStatusStyle = styles.delivered;
    } else if (currentStatus === "bounced") {
      currentStatusStyle = styles.bounced;
    } else if (currentStatus === "cancelled") {
      currentStatusStyle = styles.cancelled;
    }

    return (
      <div className={styles.currentStatusContainer}>
        <div className={currentStatusStyle}>{currentStatus}</div>
      </div>
    );
  };

  render() {
    let campaignTitle = this.state.emailCampaign.name || "Emails";

    return (
      <Container fluid className={styles.emailsScreen}>
        <Header />
        <Row>
          <ContactsLeftMenu />
          <Col>
            <div className={styles.emailsHeaderControls}>
              <div>&nbsp;</div>
            </div>
            <div className={styles.emailsTableContainer}>
              <TableView
                label={campaignTitle}
                items={this.state.emails}
                itemFields={["status", "to", "sent_at", "clicks", "downloads"]}
                //itemClickFunction={editItem}
                largeColumns={["to"]}
                dateColumns={["sent_at"]}
                statusColumns={["status"]}
                customStatusStyler={this.renderStatus}
                backLink={true}
                handleBackLink={this.handleBackLink}
              />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Emails;
