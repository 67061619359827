import React, { useState, useEffect, useRef } from "react";
import WebViewer from "@pdftron/webviewer";
import httpClient from "../../lib/HttpClient";
import axiosClient from "../../lib/axios-client";
import download from "downloadjs";
import security from "../../services/Security";
import axios from "../../lib/axios-client";
import { CSSTransition } from "react-transition-group";
import { Container, Row, Col } from "react-grid-system";
import { apiUrl } from "../../lib/axios-client";

import styles from "./Unsubscribe.module.scss";

import { set } from "date-fns";

const Unsubscribe = (props) => {
  const contactEmail = props.match.params.contact_email;
  const beforeunload = async () => {
    //
  };

  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    //
  }, []);

  const unsubscribeContact = () => {
    httpClient.put("/unsubscribe", {
      contact: {
        email: contactEmail,
      },
    });
    setShowSuccess(true);
  };

  return (
    <div className={styles.reviewerScreenContainer}>
      <div id="usersScreenHeader"></div>
      <div className={styles.headerContainer}>
        <textarea
          className={`uik-input__input `}
          style={{ width: "30%", margin: "20px auto" }}
          rows="1"
          label="Caption"
          placeholder="Type some text"
          readonly="readonly"
        >
          {contactEmail}
        </textarea>
        <button
          className={styles.unsubscribeButton}
          onClick={() => unsubscribeContact()}
        >
          Unsubscribe
        </button>
        {showSuccess && <span className={styles.successMessage}>Done!</span>}
      </div>
    </div>
  );
};

const useStateRef = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return [value, setValue, ref];
};

export default Unsubscribe;
